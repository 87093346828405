<div [ngClass]="{'container-fluid': isLoggedIn==true, 'container-fluid':isLoggedIn==false }" *ngIf="!loading">
  <!-- <div class="row btn-mar float-right">
        <button class="offset-4 col-2 mr-2 btn-mid-blu" nz-button nzType="primary" (click)="sendEmail()">
          <i nz-icon nzType="mail" nzTheme="outline"></i> EMAIL
        </button>
        <button class="col-2 btn-mid-blk" nz-button nzType="default">
          <i nz-icon type="printer"></i> PRINT
        </button>
      </div> -->
  <div class="row btn-mar1 custom-buttons">
    <div class="col-12" style="float:right;padding-right:0 !important ">
      <button class="mr-2 btn-mid-blu" nz-button nzType="primary" (click)="sendEmail()">
        <i nz-icon nzType="mail" nzTheme="outline"></i> EMAIL
      </button>
      <!-- <a href="javascript:window.print()" (click)="sendEmail()">
      <button class="btn-mid-blk" nz-button nzType="default">
        <i nz-icon type="printer"></i> PRINT & EMAIL
      </button></a> -->
    </div>
  </div>
  <div class="row address-bg pb-5">
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 custom-address">
      <h4 class="text-black">
        <strong>Genesis Homes</strong>
        <br />
      </h4>
      <address>
        Factory: {{estimateData?.factoryName}}
        <br />
        Sales Contact: {{estimateData?.modelData?.salesContact[0]}}
        <br />        
          Phone:       
        {{estimateData?.modelData?.salesContact[1]}}
        <br />    
          E-mail:
        {{estimateData?.modelData?.EmailAdress}}
        <br />
        Model: 
        {{estimateData?.modelData?.model[0]}}
        <br />
        Construction Code:
        {{estimateData?.modelData?.constructionCode}}
        <br />
        <!-- Base Price: 
        ${{estimateData?.modelData?.basePrice[0]}} &nbsp; ${{estimateData?.modelData?.basePrice[1] | number:'1.0-0'}} <small>(Includes all  Fees & Surcharges)</small>
        <br />
        Estimated Transportation Charges:
        ${{estimateData?.transportationCharges | number:'1.0-0'}}
        <br />
        Size/Dimensions:
        {{estimateData?.modelData?.dimensions.width}}x{{estimateData?.modelData?.dimensions.height}}
        <br />
        Square Footage:
        {{estimateData?.modelData?.squareFootage}} -->
      </address>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
      <img class="client-img" src='../../../../assets/images/logo-genesis-homes-black.png' title="Genesis" alt="no-image" />
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 custom-estimate">
      <h4 class="text-black">
        <strong>Estimate Info</strong>
      </h4>
      <!-- <p>
        <a class="font-size-20" href="javascript: void(0);">
          {{quoteId}}
        </a>
        <br />
       
      </p> -->
      <address>
        Customer : {{estimateData?.estimateData?.customerName}}<br />
        Company : {{estimateData?.estimateData?.company}}
        <br />
        Email : {{estimateData?.estimateData?.customerEmail}}
        <br />
<!--       
      <span>Estimate Date: {{estimateData?.estimateData?.created_at | date: 'MM/dd/yyyy'}}</span>
      <br />
      <span>Expiry Date: {{date | date: 'MM/dd/yyyy'}}
      </span>
      <br /> -->
      Ship to Metro Area: 
        {{estimateData?.estimateData?.city}}, {{estimateData?.estimateData?.state}}
      </address>
    </div>
  </div>
  <nz-table class="table-responsive" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.modelData?.popularFeatures">
      <thead>
        <tr>
          <th>Base Price</th>
          <th> $ / sq. ft.</th>
          <th>Estimated Transportation Charges</th>
          <th>Size/Dimensions</th>
          <th>Square Footage</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${{estimateData?.modelData?.basePrice[0] | number:'1.0-0'}}</td>
          <td>${{estimateData?.modelData?.basePrice[1] | number:'1.0-0'}}</td>
          <td>${{estimateData?.transportationCharges | number:'1.0-0'}}</td>
          <td>{{estimateData?.modelData?.dimensions?.width}}x{{estimateData?.modelData?.dimensions?.height}}</td>
          <td>{{estimateData?.modelData?.squareFootage}}</td>
        </tr>
      </tbody>
    </nz-table><br><br>
  <div class="row bill-info">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="pb-5">
        <h2>Popular Features</h2>
        <nz-table class="table-responsive" #basicTable [nzShowPagination]="false" [nzLoading]="loading" [nzData]="estimateData?.modelData?.popularFeatures">
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of estimateData?.modelData?.popularFeatures | slice: 0:estimateData?.modelData?.popularFeatures.length-1;let i= index">
              <td>{{i +1}}</td>
              <td>{{data.item}}</td>
              <td *ngIf="data.value != 0">${{data.value | number:'1.0-0'}}</td>
              <td *ngIf="data.value == 0">Inc.</td>
            </tr>
            <tr>
              <td></td>
              <td><b class="final-txt">Popular Features Subtotal:</b></td>
              <td><b class="final-amount">${{estimateData?.modelData?.popularFeatures[estimateData?.modelData?.popularFeatures.length-1].value | number:'1.0-0'}}</b></td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div>
        <h2>Market/Regional Construction Features</h2>
        <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.modelData?.constructionFeatures">
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of estimateData?.modelData?.constructionFeatures | slice: 0:estimateData?.modelData?.constructionFeatures.length-1;let i= index">
              <td>{{i + 1}}</td>
              <td>{{data.item}}</td>
              <td *ngIf="isNumber(data.value) && data.value != 0">${{data.value | number:'1.0-0'}}</td>
              <td *ngIf="isNumber(data.value) && data.value == 0">Inc.</td>
              <td *ngIf="!isNumber(data.value)"><i>{{data.value}}</i></td>
            </tr>
            <tr>
              <td></td>
              <td><b class="final-txt">Market/Regional Construction Features Subtotal:</b></td>
              <td><b class="final-amount">${{estimateData?.modelData?.constructionFeatures[estimateData?.modelData?.constructionFeatures.length-1].value | number:'1.0-0' }}</b></td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <br><br>
      <div class="card">
          <div class="card-body text-center">
            <h5>Typical Home Price</h5>
            <div class="row mt-5">
              <div class="col-6">
                <h5><b>Total</b></h5>
              </div>
              <div class="col-6">
                <h5 class="final-amount"><b>${{estimateData?.TypicalHomePrice?.Total | number:'1.0-0'}}</b></h5>
              </div>
            </div> 
            <div class="row mt-2">
                <div class="col-6">
                  <h5><b>Per sq. ft.</b></h5>
                </div>
                <div class="col-6">
                  <h5 class="final-amount"><b>${{estimateData?.TypicalHomePrice?.PerSqft | number:'1.0-0'}}</b></h5>
                </div>
              </div>       
          </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="pb-5">
        <h2>Featured Options Displayed</h2>
        <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.modelData?.featuredOptions">
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of estimateData?.modelData?.featuredOptions | slice: 0:estimateData?.modelData?.featuredOptions.length-1;let i= index">
              <td>{{i + 1}}</td>
              <td *ngIf="isNumber(data.value)">{{data.item}}</td>
              <td *ngIf="!isNumber(data.value)"><i>{{data.item}}</i></td>
              <td *ngIf="isNumber(data.value) && data.value == 0">Inc.</td>
              <td *ngIf="isNumber(data.value) && data.value != 0">${{data.value | number:'1.0-0'}}</td>
              <td *ngIf="!isNumber(data.value)"><i>{{data.value}}</i></td>
              

            </tr>
            <tr>
              <td></td>
              <td><b class="final-txt">Featured Options Subtotal:</b></td>
              <td><b class="final-amount">${{estimateData?.modelData?.featuredOptions[estimateData?.modelData?.featuredOptions.length-1].value | number:'1.0-0'}}</b></td>
            </tr>
          </tbody>
        </nz-table><br> <br>
        <div class="card">
            <div class="card-body text-center">
              <h5>Featured Home Delivered to Site</h5>
              <div class="row mt-5">
                <div class="col-6">
                  <h5><b>Total</b></h5>
                </div>
                <div class="col-6">
                  <h5 class="final-amount"><b>${{estimateData?.FeaturedHomeDelivered?.Total | number:'1.0-0'}}</b></h5>
                </div>
              </div>  
              <div class="row mt-2">
                  <div class="col-6">
                    <h5><b>Per sq. ft.</b></h5>
                  </div>
                  <div class="col-6">
                    <h5 class="final-amount"><b>${{estimateData?.FeaturedHomeDelivered?.PerSqft | number:'1.0-0'}}</b></h5>
                  </div>
                </div>      
            </div>
          </div>
      </div>
     
      <div class="pb-5">
        <h2>"Site Specific" ESTIMATED Costs and/or Allowances</h2>
        <strong><p style="font-size: 14px;">Note: These costs are for reference only & are the responsibility of the Builder</p></strong>
        <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.EstimatedSiteSpecificData" *ngIf="estimateData?.modelData?.constructionCode != 'MH' && estimateData?.modelData?.constructionCode !='IRC' && estimateData?.modelData?.constructionCode !='HUD'">
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>MH</th>
              <th>MOD</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of estimateData.EstimatedSiteSpecificData | slice: 0:estimateData.EstimatedSiteSpecificData.length-1;let i= index">
              <td>{{i + 1}}</td>
              <td>{{item.item}}</td>
              <td *ngIf="item.MH != 0">${{item.MH | number:'1.0-0'}}</td>
              <td *ngIf="item.MH == 0">Inc.</td>
              <td *ngIf="item.MOD != 0">${{item.MOD | number:'1.0-0'}}</td>
              <td *ngIf="item.MOD == 0">Inc.</td>
            </tr>
            <tr>
            <td></td>
              <td><b class="final-txt">ESTIMATED "Site Specific" Subtotal:</b></td>
              <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length -1]?.MH | number  : '1.2-2'}}</b></td>
              <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length -1]?.MOD | number  : '1.2-2'}}</b></td>

            </tr>
          </tbody>
        </nz-table>
        <nz-table class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.EstimatedSiteSpecificData" *ngIf="estimateData?.modelData?.constructionCode == 'MH'">
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>MH</th>
                <!-- <th>MOD</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of estimateData.EstimatedSiteSpecificData | slice: 0:estimateData.EstimatedSiteSpecificData.length-1;let i= index">
                <td>{{i + 1}}</td>
                <td>{{item.item}}</td>
                <td>${{item.MH | number:'1.0-0'}}</td>
                <td *ngIf="item.MH == 0">Inc.</td>
                <!-- <td>${{item.MOD | number:'1.0-0'}}</td> -->
                <!-- <td *ngIf="item.MOD == 0">Inc.</td> -->
              </tr>
              <tr>
              <td></td>
                <td><b class="final-txt">ESTIMATED "Site Specific" Subtotal:</b></td>
                <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length -1]?.MH | number  : '1.2-2'}}</b></td>
                <!-- <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length -1]?.MOD | number  : '1.2-2'}}</b></td> -->
  
              </tr>
            </tbody>
          </nz-table>
        <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.EstimatedSiteSpecificData" *ngIf="estimateData?.modelData?.constructionCode == 'IRC'">
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>IRC</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of estimateData?.EstimatedSiteSpecificData | slice: 0:estimateData?.EstimatedSiteSpecificData.length-1;let i= index">
                <td>{{i + 1}}</td>
                <td>{{item.item}}</td>
              <td *ngIf="isNumber(item.value)">${{item.value | number:'1.0-0'}}</td>
              <td *ngIf="!isNumber(item.value) && item.value != 0"><i>{{item.value}}</i></td>
              <td *ngIf="isNumber(item.value) && item.value == 0">Inc.</td>

              </tr>
              <tr>
                <td></td>
                <td><b class="final-txt">ESTIMATED "Site Specific" Subtotal:</b></td>
                <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length-1].value | number  : '1.2-2'}}</b></td>
              </tr>
            </tbody>
          </nz-table>
          <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.EstimatedSiteSpecificData" *ngIf="estimateData?.modelData?.constructionCode == 'HUD'">
            <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>HUD</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of estimateData?.EstimatedSiteSpecificData | slice: 0:estimateData?.EstimatedSiteSpecificData.length-1;let i= index">
                <td>{{i + 1}}</td>
                <td>{{item.item}}</td>
              <td *ngIf="isNumber(item.value)">${{item.value | number:'1.0-0'}}</td>
              <td *ngIf="!isNumber(item.value) && item.value != 0"><i>{{item.value}}</i></td>
              <td *ngIf="isNumber(item.value) && item.value == 0">Inc.</td>

              </tr>
              <tr>
                <td></td>
                <td><b class="final-txt">ESTIMATED "Site Specific" Subtotal:</b></td>
                <td><b class="final-amount">${{estimateData?.EstimatedSiteSpecificData[estimateData?.EstimatedSiteSpecificData.length-1].value | number  : '1.2-2'}}</b></td>
              </tr>
            </tbody>
          </nz-table>
      </div>

    </div>
  </div>

  <div class="row bill-info pb-5">
    <div class="col-12">
      <h2>Total ESTIMATED Home and Site Costs:</h2>
      <nz-table  class="table-responsive" [nzLoading]="loading" #basicTable [nzShowPagination]="false" [nzData]="estimateData?.totalEstimatedCosts">
        <thead>
          <tr>
            <th>Item</th>
            <th>Total</th>
            <th>Per sq. ft.</th>
          </tr>
        </thead>
        <tbody  *ngIf="estimateData?.modelData?.constructionCode != 'MH'">
          <tr *ngFor="let item of estimateData?.totalEstimatedCosts;let i= index">
            <td *ngIf="i != 2">{{item.item}}</td>
            <td *ngIf="i == 2">{{item.item}}*</td>
            <td>${{item.Total | number:'1.0-0'}}</td>
            <td>${{item.PerSqft | number:'1.0-0'}}</td>
          </tr>
        </tbody>
        <tbody  *ngIf="estimateData?.modelData?.constructionCode == 'MH'">
          <tr *ngFor="let item of estimateData?.totalEstimatedCosts | slice: 0:estimateData.totalEstimatedCosts.length-1 ;let i= index">
            <td *ngIf="i != 2">{{item.item}}</td>
            <td *ngIf="i == 2">{{item.item}}*</td>
            <td>${{item.Total | number:'1.0-0'}}</td>
            <td>${{item.PerSqft | number:'1.0-0'}}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>

  <p style="font-size: 14px;">* Contact the sales representative listed on this estimate for information on MOD conversions</p>
  <div class="row disclaimer">
    <div class="col-12">
      <p class="txt-black"><span class="txt-red">Disclaimer:&nbsp;</span>This is not a quote. Wholesale pricing for models and options is valid until February 28, 2022. Delivery, installation, and site construction costs are rough estimates only. See your Genesis/Champion representative for more details. Genesis is 
        a registered trademark of Champion Home Builders, Inc. All rights reserved.
      </p>
    </div>
  </div>
</div>