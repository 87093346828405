<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="text-center pr-5 upload" style="cursor: pointer;" (click)="upload()">
        <div class="box" style="text-align: center">
            <i class="fa fa-upload"></i>
          </div><br>
            <p style="color:#0190fe"><b>Upload Model</b></p>
    </div>
  </div>
</div>

<!-- <div class="container-fluid">
  <div class="row">
      <div class="col-12 p-0">
          <div class="min-vh-100 text-center m-0  d-flex flex-column justify-content-center">
            <span style="cursor: pointer;" class="text-center pr-5" (click)="upload()">
              <div class="box" style="text-align: center">
                  <i class="fa fa-upload"></i>
                </div><br>
                  <p style="color:#0190fe"><b>Upload Model</b></p>
              </span>
          </div>
      </div>
  </div>
</div> -->