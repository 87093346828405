import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('championUser') == null) {
      this.router.navigate(['/login'])
    }
  }

  upload() {
    this.router.navigate(['dashboard/upload'])
  }
  users() {
    this.router.navigate(['dashboard/manage'])
  }
}

