import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent  {
  confirmModal: NzModalRef;
  fileToUpload: File = null;
  file: any;
  fileName: any;
  loading: any;
  event: any;
  divisionCode: any;
  constructor(private msg: NzMessageService,private apis: DataService,private router: Router,private modal: NzModalService) {
    if(localStorage.getItem('championUser')==null){
      this.router.navigate(['/login'])
    } 
    
  }
  uploadUrl = this.apis.uploadUrl1;

  changeListener($event) : void {
    this.event = $event;
    if($event.type == "success"){
     this.readThis($event.file.originFileObj);
    }
    
  }
  
  readThis(inputValue: any): void {
    var file:File = inputValue;
    this.fileName = inputValue.name;
    var myReader:FileReader = new FileReader();
    this.loading = this.msg.loading('Action in progress..', { nzDuration: 0 }).messageId
    myReader.onloadend = (e) => {
      this.file = myReader.result;
      let data = {
        "file": this.file,
       "name": this.fileName
      }
        this.apis.postApi(this.apis.uploadUrl,data)
  .subscribe((response) => {
       this.msg.remove(this.loading);
       if(response != undefined && response.msg == "File uploaded/import successfully!"){
         this.event = "";
         alert(`${file.name} uploaded successfully.`)
        this.msg.success(`${file.name} uploaded successfully.`);
        setTimeout(() => {
         this.router.navigate(['dashboard/admin'])
        }, 3000);
       } else if(response != undefined && response.msg == "File Already Exists!"){
        this.divisionCode = response.code;
        this.showConfirm();
       }else{
        this.msg.error(`Something went Wrong,Please upload again.`);
        setTimeout(() => {
          this.router.navigate(['dashboard/admin'])
         }, 3000);
       }
  })
    }
    myReader.readAsDataURL(file);
  }

  updateFile($event){
    
    var file:File = $event.file.originFileObj;
    this.fileName = $event.file.originFileObj.name;
    var myReader:FileReader = new FileReader();
    this.loading = this.msg.loading('Action in progress..', { nzDuration: 0 }).messageId
    myReader.onloadend = (e) => {
      this.file = myReader.result;
      let data = {
        "file": this.file,
       "name": this.fileName,
       "divisionCode": this.divisionCode
      }
      console.log("division Code1",this.divisionCode)
        this.apis.postApi(this.apis.updateFileUrl,data)
  .subscribe((response) => {
       this.msg.remove(this.loading);
       if(response != undefined && response.msg == "File updated!"){
         this.event = "";
         alert(`${file.name} uploaded successfully.`)
        this.msg.success(`${file.name} updated successfully.`);
        setTimeout(() => {
         this.router.navigate(['dashboard/admin'])
        }, 3000);
       }else{
        this.msg.error(`Something went Wrong,Please upload again.`);
        setTimeout(() => {
          this.router.navigate(['dashboard/admin'])
         }, 3000);
       }
  })
    }
    myReader.readAsDataURL(file);
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'File already exists, Do you want to override?',
      nzContent: 'Click on ok to confirm.',
      nzOnCancel: ()=>{
        this.router.navigate(['dashboard/admin'])
      },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // this.router.navigate(['dashboard/admin'])
           setTimeout(Math.random() > 0.5 ? resolve : reject, 10);
          this.updateFile(this.event)
          
        }).catch(() => {
          this.router.navigate(['dashboard/admin'])
        })
    });
  }

}