import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/LayoutComponents/Guard/auth.guard'

// layouts & notfound
import { LayoutLoginComponent } from 'src/app/layouts/Login/login.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'
// import { NotFoundComponent } from 'src/app/pages/404.component'

// user
// import { ForgotComponent } from 'src/app/pages/user/forgot/forgot.component'

//custom components
import { LoginComponent } from './components/CustomComponents/login/login.component';
import { HomeComponent } from './components/CustomComponents/home/home.component';
import { AdminComponent } from './components/CustomComponents/admin/admin.component';
import { UploadComponent } from './components/CustomComponents/upload/upload.component';
import { QuoteComponent } from './components/CustomComponents/quote/quote.component'
import { RoleGuard } from './guards/role-guard.service';
import { DashboardComponent } from './components/CustomComponents/dashboard/dashboard.component';

const COMPONENTS = [
  LoginComponent,
  HomeComponent,
  AdminComponent,
  UploadComponent,

  QuoteComponent,

]

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'dashboard' },
    children: [{
      path: '',
      component: HomeComponent,
      data: { title: 'dashboard',
      role: 'User' },
      canActivate: [RoleGuard]
    },
    {
      path: 'quote/:id',
      component: QuoteComponent,
      data: { title: 'Quote',
      role: 'User' },
    },
     {
      path: 'admin',
      component: AdminComponent,
      data: { title: 'Admin',
      role: 'Admin' },
      canActivate: [RoleGuard]
    },
    {
      path: 'upload',
      component: UploadComponent,
      data: { title: 'Upload',
      role: 'Admin' },
      canActivate: [RoleGuard]
    }]
  },
 
  // {
  //   path: 'oldComponents',
  //   component: LayoutMainComponent,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       loadChildren: 'src/app/pages/dashboard/dashboard.module#DashboardModule',
  //     },
  //     {
  //       path: 'antd',
  //       loadChildren: 'src/app/pages/antd/antd.module#AntdModule',
  //     },
  //     { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] },
  //   ],
  // },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  declarations: [
    LoginComponent,
    HomeComponent,
    AdminComponent,
    UploadComponent,
    QuoteComponent,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
