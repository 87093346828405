import { Component } from '@angular/core'
import { Router } from '@angular/router';

@Component({
  selector: 'cui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  userDetails: any;
  constructor(private router: Router) { 
    this.userDetails = JSON.parse(localStorage.getItem('championUser'));
  }
  
  logout(){
    this.router.navigateByUrl('/login');
    localStorage.clear();
  }
}
