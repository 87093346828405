import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  userDetails: any ={};
  isLoggedIn = false;
  constructor(private router: Router) { 
//this.userDetails = localStorage.getItem('championUser');
    if(localStorage.getItem('championUser') != null) {
      this.isLoggedIn = true;
      this.userDetails= JSON.parse(atob(localStorage.getItem('championUser')));

    }
    // this.userDetails= JSON.parse(atob(localStorage.getItem('championUser')))
  }
  
  logout(){
    this.router.navigateByUrl('/login');
    localStorage.clear();
  }
}
