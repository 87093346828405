import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isLoggedIn = false;
  userDetails: any ={};
  constructor() { 
    if(localStorage.getItem('championUser') != null) {
      this.isLoggedIn = true;
      this.userDetails= JSON.parse(atob(localStorage.getItem('championUser')));

    }
  }
  
  ngOnInit() {
  }

}
