<div class="container h-100">
  <div *ngIf="!startFlow" class="row">
    <div class="div-center" style="margin-top: -40px;">
      <img src="../../../../assets/images/logo-genesis-homes-black.png"
        style="width:250px;left:0;right:0;margin:auto;display: block;">
        <br>
      <h4 class="py-3">Welcome to Genesis Homes Estimator Tool. Click Start to Continue.</h4>
      <button class="btn btn-client start" (click)="start()">Start</button>
    </div>
  </div>
  <div *ngIf="startFlow" class="row">
    <div class="col-md-12 div-center stepper-custom">
        <nz-steps [nzCurrent]="current">
            <nz-step nzTitle="User Details"></nz-step>
            <nz-step nzTitle="Project Details"></nz-step>
            <nz-step nzTitle="Select Model"></nz-step>
          </nz-steps>
      
          <div class="steps-content">
            <div class="container text-center">
              <div class="row">
                <div class="col-md-2 col-sm-0"></div>
                <div class="col-md-8 col-sm-12">
                  <form nz-form [nzLayout]="'vertical'" [formGroup]="firstForm" class="text-center">
                    <div *ngIf="index == 1">
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="username">Name</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
                          <input nz-input formControlName="username" id="username" placeholder="eg. John Doe" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">E-mail</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
                          <input nz-input formControlName="email" id="email" placeholder="eg. john@example.com" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="company">Company</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid company">
                          <input nz-input formControlName="company" id="company" placeholder="eg. Genesis Homes" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber">Phone Number</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid company">
                          <input nz-input formControlName="phoneNumber" id="phoneNumber" placeholder="eg. 1234567899" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </form>
                  <form nz-form [nzLayout]="'vertical'" [formGroup]="secondForm" class="text-center">
                    <div *ngIf="index == 2">
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="state">State</nz-form-label>
                        <nz-form-control [nzSm]="7" [nzXs]="14" nzErrorTip="The input is not valid">
                          <!-- <nz-select #mySelect nzShowSearch nzAllowClear nzPlaceHolder="Select State"
                            (ngModelChange)="getCities($event)" formControlName="state">
                            <nz-option
                              nzLabel="Select State &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;"
                              nzValue="" nzHide></nz-option>
                            <nz-option *ngFor="let data of states" [nzLabel]=data.state [nzValue]=data></nz-option>
                          </nz-select> -->
                          <select class="form-control" placeholder="Select State" formControlName="state" id="exampleFormControlSelect1" (change)="getCities($event.target.value)">
                              <option selected disabled>Select State</option>
                            <option *ngFor="let data of states"  [value]=data.state>{{data.state}}</option>             
                          </select>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="city">Metro Area</nz-form-label>
                        <nz-form-control [nzSm]="7" [nzXs]="14" nzErrorTip="The input is not valid">
                          <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Metro Area" [nzDisabled]="isCity"
                            formControlName="city">
                            <nz-option
                              nzLabel="Select Metro Area &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;"
                              nzValue="" nzHide></nz-option>
                            <nz-option style="width: 120px;" *ngFor="let data of cities" [nzLabel]=data.city [nzValue]=data>
                            </nz-option>
                          </nz-select> -->
                          <select class="form-control" placeholder="Select Metro Area" formControlName="city" >
                              <option selected disabled>Select Metro Area</option>
                            <option *ngFor="let data of cities"  [value]=data.city>{{data.city}}</option>             
                          </select>
                        </nz-form-control>
                      </nz-form-item>
      
                    </div>
                  </form>
      
                  <div *ngIf="index == 3">
                    <div class="row" style="display: flex;justify-content: center;">
      
      
                      <div *ngFor="let model of models" class="card px-1 py-1 box-shadow previewCard col-sm-3 mr-3 ml-3">
      
                        <div class="card-body " [ngClass]="{isSelected: imageIndex == model }" style="cursor: pointer" (click)="previewImage(model)">
                          {{model.data.model[0]}}
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div>
                <div class="col-md-2 col-sm-0"></div>
              </div>
            </div>
          </div>
          <div class="steps-action">
            <button nz-button class="prevButton" nzType="default" (click)="pre()" *ngIf="current > 0">
              <span>Previous</span>
            </button>
            <button nz-button nzType="default" class="nextButton" (click)="next()" [disabled]="!firstForm.valid"
              *ngIf="current == 0">
              <span>Next</span>
            </button>
            <button nz-button nzType="default" class="nextButton" (click)="next()" [disabled]="!secondForm.valid"
              *ngIf="current == 1">
              <span>Next</span>
            </button>
            <button nz-button nzType="primary" class="nextButton" [disabled]="!selectedImage" (click)="submit()"
              *ngIf="current === 2">
              <span>Submit</span>
            </button>
          </div>
    </div>
    
  </div>
</div>