import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {

  constructor(private _authService: AuthService, private _router: Router,private notification: NzNotificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(localStorage.getItem('championUser') != null){
    const user:any = JSON.parse(atob(localStorage.getItem('championUser')))
    if (user.role == next.data.role) {
      return true;
    }
else{
    // navigate to not found page
    this.notification.error(
      'No Access',
      'You have not Authorized to access this page!',
    )
    this._router.navigate(['/login']);
    return false;
}
} else{
  // navigate to not found page
  this.notification.error(
    'Logged failed!',
    'Please login to access this page!',
  )
  this._router.navigate(['/login']);
  return false;
}
  }
}
