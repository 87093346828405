<div class="" *ngIf="isLoggedIn">
  <div class="row">
      <div class="">
          <a [routerLink]="['/dashboard/']" routerLinkActive="router-link-active" >
          <img src="../../../../assets/images/logo-genesis-homes-white.png" style="position: absolute;
             width: 9rem;
           left: 53px;
            top: 17px;">        
        </a>    
      </div>
      <div class="login-info">
          <p>Hello <span style="color:#00aae7;font-weight:400">{{userDetails.name}}</span><i style="cursor: pointer" class="fa fa-sign-out mr-3 ml-3" (click)="logout()"></i></p>
      </div>
  </div>
</div>
<!-- <div class="container-fluid d-flex justify-content-between"> 
          <a [routerLink]="['/dashboard/']" routerLinkActive="router-link-active" >
          <img src="../../../../assets/images/logo-white.png" style="height: 4rem;width: 4rem;">        
        </a>    
        <div  *ngIf="isLoggedIn">
          <p class="login-info">Hello <span style="color:#00aae7;font-weight:400">{{userDetails.name}}</span><i style="cursor: pointer" class="fa fa-sign-out mr-3 ml-3" (click)="logout()"></i></p>
      </div>

</div> -->
