<div class="container h-100 justify-content-center align-items-center">
  <img src="../../../../assets/images/logo-genesis-homes-black.png" class="logo">
<div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
            <nz-upload
            nzType="drag"
            [nzMultiple]="false"
            [nzAccept]="['.xlsx']"
            [nzAction]="uploadUrl"
            (nzChange)="changeListener($event)"
          >
            <p class="ant-upload-drag-icon">
              <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
            </p>
          </nz-upload>
      </div>
    </div>
  </div>
</div>
</div>