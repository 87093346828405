import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  backgroundNumber = 1
  fullScreen = true
  validateForm: FormGroup
  constructor(private fb: FormBuilder,
    private msg: NzMessageService,
     public authService: AuthService,
     private router: Router,
     private apis: DataService,
     private notification: NzNotificationService) {

  }

  get email() {
    return this.validateForm.controls.email
  }
  get password() {
    return this.validateForm.controls.password
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  login(){
    let data = {
      "email": this.validateForm.controls.email.value,
      "password": this.validateForm.controls.password.value
    }
    // this.authService.SignIn(data)
    
    this.apis.postApi(this.apis.loginUrl,data).subscribe(res =>{
      if(res != undefined){
      if(res.data != undefined){
      localStorage.setItem('championUser', btoa(JSON.stringify(res.data)));
      if(res.data.role == "Admin"){
        this.router.navigate(['/dashboard/admin'])

      } else {
        this.router.navigate(['/dashboard/'])

      }
    } else if(res.status == "InCorrect Password") {
      this.msg.error(`Invalid Credentials!`);
    } else if(res.status == "Invalid Email Id") {
      this.msg.error(`Invalid Email Id!`);
    }  
  } else {
    this.msg.error(`Api Not Responding, Please try again!`);
  }
    }) 
  }
}
