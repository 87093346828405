<section class="login fullScreen">
  <div class="overlay">
  </div>
  <div class="content">
    <div class="formWrapper custom-formwrapper">
      <div class="form">
        
          <img class="login-custom-image img-responsive-image" src="../../../../assets/images/genesis-logo.png" style="width:50px;margin:auto;" alt="">        
        <p class="formTitle login-text">Please log in</p>
      
        <form nz-form [nzLayout]="'vertical'" [formGroup]="validateForm">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
            <nz-form-control>
              <nz-input-group [nzPrefix]="prefixUser">
                <input formControlName="email" id="email" nz-input placeholder="eg. john@genesishomes.com">
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired nzFor="password">Password</nz-form-label>
            <nz-form-control>
              <nz-input-group [nzPrefix]="prefixLock">
                <input type="password" formControlName="password" nz-input placeholder="Password" id="password">
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <div class="form-actions">
            <button nz-button nzType="primary" class="login-form-button mr-3" [disabled]="!validateForm.valid"
              (click)="login()">
              Sign in
            </button>
          </div>
          <div class="row">
            <p style="margin:auto">Copyright © Genesis Homes</p>
          </div>
        </form>
      </div>
      <div class="sidebar" style="text-align: center">
        <a href="javascript: void(0);">
          <img src="../../../../assets/images/logo-genesis-homes-white.png" alt="Champion Homes Quote App" style="padding: 2rem;height: 109px;position: relative;left: 1px;top: 14px;">
        </a>
        <!-- <p class="sidebarTitle">International Builders Show 2020 </p> -->
        <div class="sidebarContent">

        </div>
        <div class="sidebarFooter">
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #prefixUser><i nz-icon type="user"></i></ng-template>
<ng-template #prefixLock><i nz-icon type="lock"></i></ng-template>