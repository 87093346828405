import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
declare var require: any
const invoiceData: any = require('./data.json')

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  data = invoiceData.invoiceData;
  estimateData: any = {};
  quoteId: any;
  date: any;
  loading = true;
  isLoggedIn = false;
  constructor(private notification: NzNotificationService,private apis: DataService, private router: Router,private route: ActivatedRoute) {
    if(localStorage.getItem('championUser') != null) {
      this.isLoggedIn = true;
    }
    this.notification.config({
      nzPlacement: "bottomRight"
    })
    this.route.params.subscribe(params => {
      let data = {
        id: params.id,
      }
     
      this.quoteId = params.id
      console.log("params",params)
      this.apis.postApi(this.apis.getEstimate,data).subscribe(res => {
        console.log("Generated Quote Data",res);
        this.estimateData = res;
        this.loading = false;
        this.date = new Date(this.estimateData.estimateData.created_at);
        this.date = this.date.setDate( this.date.getDate() + 30 );
        })
      })
   }
   isNumber(val)
    {
       return typeof val === 'number'; 
      }
  ngOnInit() {
    // if(localStorage.getItem('championUser')==null){
    //   this.router.navigate(['/login'])
    // }
    
  }
  createBasicNotification(): void {
    this.notification.blank(
      'Email Sent Succesfully',
      'You will be redirected to home page after 3 seconds',
      { nzDuration: 3000 }
    );
  }

  sendEmail(){  
    let body = {
      id: this.quoteId,
      name: this.estimateData.estimateData.customerName,
      details: this.estimateData.estimateData,
      email: this.estimateData.modelData.EmailAdress[0],
      modelName:this.estimateData.modelData.model[0]
    }
    this.apis.postApi(this.apis.sendEmailUrl,body).subscribe(res =>{
      this.createBasicNotification()
      setTimeout(() => {
        this.router.navigate(['dashboard/'])
      }, 3500);
    })
    
  }
}
