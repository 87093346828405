import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'layout-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LayoutLoginComponent {
  backgroundNumber = 1
  fullScreen = true
  validateForm: FormGroup

  constructor(private fb: FormBuilder,private router: Router) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  login(){
    this.router.navigateByUrl('/dashboard')
  }
}
