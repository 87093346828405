import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  rootUrl = environment.rootUrl;

  loginUrl = this.rootUrl + "users/login";
  uploadUrl = this.rootUrl + "uploadModel";
  uploadUrl1 = this.rootUrl + "upload";
  updateFileUrl = this.rootUrl + "updateModel";
  estimateUrl = this.rootUrl + "users/estimate";
  getMetroCitiesUrl = this.rootUrl + "users/getMetroCitites";
  getModalData = this.rootUrl + "users/getModelsData";
  getEstimate = this.rootUrl + "users/calculateFormula";
  sendEmailUrl = this.rootUrl + "users/sendEmail";

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',"Access-Control-Allow-Origin":"*","Access-Control-Allow-Methods": "PUT,GET,POST,DELETE","Access-Control-Allow-Headers" :" Origin, X-Requested-With, Content-Type, Accept"
    })
  }  

  getApi(endpoint): Observable<any> {
    return this.http.get(endpoint).pipe(
      map(this.extractData));
  }

  postApi(endpoint, data): Observable<any> {
    return this.http.post<any>(endpoint, data, this.httpOptions).pipe(
      map((uresponse: Response) => {
        return uresponse;
      }), catchError(this.handleError<any>('endpoint'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

}
