import { Injectable } from '@angular/core'
import { auth } from 'firebase/app'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd'
import { DataService } from './data.service';
// import {Md5} from 'ts-md5/dist/md5';

interface User {
  uid: string
  email: string
  displayName: string
  photoURL: string
  emailVerified: boolean
  role: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private notification: NzNotificationService,
    private apis: DataService,
  ) {
    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user
    //     localStorage.setItem('user', JSON.stringify(this.userData))
    //   } else {
    //     localStorage.setItem('user', null)
    //   }
    // })
  }

 SignIn(data) {
    try {
      this.apis.postApi(this.apis.loginUrl,data).subscribe(res =>{
        if(res.data != undefined){
        localStorage.setItem('championUser', JSON.stringify(res.data));
        if(res.data.role == "Admin"){
          this.router.navigate(['/admin'])

        } else {
          this.router.navigate(['/home'])

        }
        this.notification.success(
          'Logged In',
          'You have successfully logged in to Champion Homes Quote App!',
        )
      }
      }) 
    } catch (error) {
      this.notification.error(error.code, error.message)
    }
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('championUser'))
    return user !== null
  }

  getToken(){
    if(localStorage.getItem('championUser') != undefined){
      let token = JSON.parse(atob(localStorage.getItem('championUser'))).token
      return token
    } else {
      return false;
    }
  }

  async SignOut() {
    // await this.afAuth.auth.signOut()
    localStorage.removeItem('championUser')
    this.router.navigate(['/login'])
  }

}
