import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  firstForm: FormGroup;
  secondForm: FormGroup;
  phoneNumber = "^(\+\d{1,3}[- ]?)?\d{10}$";
  states = []
  cities = [];
  models = [];
  array = [1, 2, 3, 4];
  selectedImage = false;
  startFlow: boolean;
  imageIndex = 0;
  model: any;
  isCity: boolean = true;
  citiValue: any = {};
  constructor(private fb: FormBuilder,private router: Router,private apis: DataService) { }

  ngOnInit() {
    if(localStorage.getItem('championUser')==null){
      this.router.navigate(['/login'])
    }
    this.firstForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required]],
      phoneNumber: [null],
      company: ['', [Validators.required]]
    })
    this.secondForm = this.fb.group({
      city: [{value: 'Select Metro Area', disabled: this.isCity}, [Validators.required]],
      state: ['Select State', [Validators.required]]
    })
    this.apis.getApi(this.apis.getMetroCitiesUrl).subscribe(states => {
      this.states = states.data;
    })
  }
 current = 0;

  index = 1;

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    if(this.current==2){
      let query={
        divisionCode:this.cities[0].divisionCode
      }
      this.apis.postApi(this.apis.getModalData,query).subscribe(res => {
        this.models=res.data
      })
    }
    this.changeContent();
  }

  done(): void {
  }

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 1;
        break;
      }
      case 1: {
        this.index = 2;
        break;
      }
      case 2: {
        this.index = 3;
        break;
      }
      default: {
        this.index = 0;
      }
    }
  }

  previewImage(i): void {
    this.selectedImage =true;
    this.model = i;
    this.imageIndex = i;
  }

  submit(){    
    this.cities.forEach(element => {
      if(element.city == this.secondForm.controls.city.value){
        this.citiValue = element
      }
    });
    let data = {
      userName :this.firstForm.controls.username.value,
      email: this.firstForm.controls.email.value,
      company: this.firstForm.controls.company.value,
      phoneNumber: this.firstForm.controls.phoneNumber.value,
      state: this.secondForm.controls.state.value,
      cityData: this.citiValue,
      width: this.model.data.dimensions.width,
      model: this.model.model
    }
    this.apis.postApi(this.apis.estimateUrl,data).subscribe(res => {
      this.router.navigate(['dashboard/quote/', res.data._id]).then(err =>{

      })

    })

  }
start(){
  this.startFlow = true;
}

getCities(event: any){
   this.secondForm.controls.city.enable();
   this.secondForm.controls.city.setValue("Select Metro Area");
  this.isCity = false;
  this.states.forEach(element => {
    if(element.state == event) {
      this.cities = element.cities;
    }
  });
  

}
}
